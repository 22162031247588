import { AutocompleteItem } from "molecule/autocomplete/AutocompleteBloc";
import { stateCodeToNameMap } from "src/constants/stateCodeToNameMap";

export const getAvailableStates = (): AutocompleteItem[] => {
  const availableStates: AutocompleteItem[] = [];
  for (const code in stateCodeToNameMap) {
    availableStates.push({
      label: stateCodeToNameMap[code],
      name: code,
      id: code
    });
  }
  return availableStates;
};
